import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import parseFlexibleContent from '../utils/parse-flexible-content';
import renderFlexibleContent from '../utils/render-flexible-content';
import { SINGLE, MULTIPLE } from '../utils/constants';
import PageHero from '../components/molecules/page-hero';
import Intro from '../components/molecules/intro';
import TextImageAside from '../components/molecules/text-image-aside';
import AdditionalContent from '../components/molecules/additional-content';
import TestimonialSection from '../components/molecules/testimonial-section';
import FAQs from '../components/molecules/faqs';
import FormRenderer from '../components/organisms/form-renderer';

const modules = {
  Hero: PageHero,
  TextImageAside,
  Intro,
  Testimonial: TestimonialSection,
  Form: FormRenderer,
  Faqs: FAQs,
  AdditionalContent,
  OnSaleForm: FormRenderer,
  OffSaleForm: FormRenderer,
};

function parseEmbedKey(key) {
  switch (key) {
    case 'offSaleForm':
      return 'form';
    case 'onSaleForm':
      return 'form';
    default:
      return key;
  }
}

const Course = ({ data }) => {
  const flexibleContent = parseFlexibleContent(data.page.acf.flexible_content_course);

  const injectData = {
    OnSaleForm: {
      form: {
        id: 'registration',
      },
    },
    OffSaleForm: {
      form: {
        id: 'registration',
      },
    },
  };

  const embeds = [
    {
      key: 'testimonial',
      type: MULTIPLE,
    },
    {
      key: 'offSaleForm',
      type: SINGLE,
    },
    {
      key: 'onSaleForm',
      type: SINGLE,
    },
  ];

  const renderConditions = {
    OnSaleForm: () => {
      return data.page.acf.on_sale;
    },
    OffSaleForm: () => {
      return !data.page.acf.on_sale;
    },
  };

  const ogImage = data.page.yoast.ogImage && data.page.yoast.ogImage.localFile;
  const twitterImage = data.page.yoast.twitterImage && data.page.yoast.twitterImage.localFile;

  return (
    <Layout>
      <Seo
        fallbackTitle={data.page.title}
        title={data.page.yoast.title}
        description={data.page.yoast.metadesc}
        ogImage={ogImage}
        twitterImage={twitterImage}
      />
      {renderFlexibleContent(flexibleContent, modules, injectData, embeds, parseEmbedKey, renderConditions)}
    </Layout>
  );
};

export const query = graphql`
  query($id: Int!) {
    page: wordpressWpCourse(wordpress_id: { eq: $id }) {
      yoast {
        title
        metadesc
        ogImage: opengraph_image {
          localFile {
            childImageSharp {
              resize(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        twitterImage: twitter_image {
          localFile {
            childImageSharp {
              resize(width: 1000, height: 1000) {
                src
              }
            }
          }
        }
      }
      title
      acf {
        on_sale
        flexible_content_course {
          module: __typename
          ... on WordPressAcf_hero {
            title
            body
            background_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1280) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
          }
          ... on WordPressAcf_intro {
            title
            body
          }
          ... on WordPressAcf_testimonial {
            testimonial {
              wordpress_id
              acf {
                attribution
                body
              }
            }
          }
          ... on WordPressAcf_faqs {
            title
            items {
              question
              answer
            }
          }
          ... on WordPressAcf_additional_content {
            title
            body
            no_background
          }
          ... on WordPressAcf_text_image_aside {
            image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 640) {
                    ...GatsbyImageSharpSizes
                  }
                }
              }
            }
            title
            body
            call_to_action_text
            call_to_action_link
          }
          ... on WordPressAcf_off_sale_form {
            off_sale_form {
              wordpress_id
              acf {
                title
                introduction
                Information {
                  title
                  value
                }
                note
                mailchimp_url
                mailchimp_user_id
                mailchimp_list_id
                cta_button_text
                success_message
                generic_error_message
                already_subscribed_error_message
                background_image {
                  localFile {
                    childImageSharp {
                      sizes(maxWidth: 640) {
                        ...GatsbyImageSharpSizes
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_on_sale_form {
            on_sale_form {
              wordpress_id
              acf {
                title
                cta_button_text
                background_image {
                  localFile {
                    childImageSharp {
                      sizes(maxWidth: 640) {
                        ...GatsbyImageSharpSizes
                      }
                    }
                  }
                }
                purchase_url
              }
            }
          }
        }
      }
    }
  }
`;

export default Course;
